import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

const Datasets = () => {
  const navigate = useNavigate();

  const datasets = [
    {
      name: "Companies Dataset",
      description:
        "A dataset containing information about companies such as their name, industry, and location.",
      link: "/datasets/companies",
      overview: "The Companies Dataset provides comprehensive information about various businesses. It includes details such as company names, industries, locations, employee counts, revenue, and social media presence. This dataset is ideal for market research, lead generation, and business intelligence applications.",
    },
    {
      name: "People Dataset",
      description:
        "A dataset containing information about people such as their name, age, and location. This dataset is useful for creating user profiles.",
      link: "/datasets/people",
      overview: "The People Dataset offers detailed information about individuals, including their personal and professional details. It contains data such as names, ages, locations, occupations, education levels, and social media profiles. This dataset is valuable for user profiling, demographic analysis, and personalized marketing strategies.",
    },
    {
      name: "Jobs Dataset",
      description:
        "A dataset containing information about jobs such as the job title, salary, and location.",
      link: "/datasets/jobs",
      overview: "The Jobs Dataset provides a comprehensive collection of job-related information. It includes details such as job titles, salaries, locations, required skills, and company information. This dataset is useful for job market analysis, career planning, and recruitment strategies.",
    },
  ];

  return (
    <div className="space-y-4" style={{ fontSize: '1.32rem', lineHeight: '1.275' }}>
      <h2 className="text-2xl font-bold text-gray-800">Datasets</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {datasets.map((dataset) => (
          <div
            key={dataset.name}
            className="p-6 bg-white rounded-xl shadow-md border-2 border-gray-100 flex flex-col justify-between"
            style={{ transform: 'scale(0.9919)' }}
          >
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">{dataset.name}</h3>
              <p className="text-sm text-gray-600 mb-4">{dataset.description}</p>
            </div>
            <Button
              onClick={() => navigate(dataset.link)}
              className="w-full bg-black text-white hover:bg-black/90"
            >
              View Dataset
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Datasets;
