import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface ApiLog {
  endpoint: string;
  status: string;
  responseTime: number;
  time: string;
}

const RecentApiLogs = ({ logs }: { logs: ApiLog[] }) => {
  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2">Recent API Logs</h3>
      <div className="border rounded-xl overflow-hidden shadow-sm">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="bg-gray-50 text-gray-600 font-semibold text-xs">
                Endpoint
              </TableHead>
              <TableHead className="bg-gray-50 text-gray-600 font-semibold text-xs">
                Status
              </TableHead>

              <TableHead className="bg-gray-50 text-gray-600 font-semibold text-xs">
                Response Time
              </TableHead>
              <TableHead className="bg-gray-50 text-gray-600 font-semibold text-xs">
                Timestamp
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {logs.map((log, index) => (
              <TableRow key={index} className="text-sm">
                <TableCell className="font-medium text-gray-700">
                  {log.endpoint}
                </TableCell>
                <TableCell>
                  <span
                    className={`px-1.5 py-0.5 rounded-full text-xs font-medium ${
                      log.status === "200"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {log.status}
                  </span>
                </TableCell>

                <TableCell className="text-gray-600">
                  {log.responseTime}ms
                </TableCell>
                <TableCell className="text-gray-600">
                  {new Date(log.time).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default RecentApiLogs;
