import { useState } from 'react';
import { Button } from "@/components/ui/button";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs/";

const Documentation = () => {
  const [activeSection, setActiveSection] = useState('introduction');

  const sections = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'authentication', title: 'Authentication' },
    { id: 'endpoints', title: 'API Endpoints' },
    { id: 'rate-limiting', title: 'Rate Limiting' },
    { id: 'error-handling', title: 'Error Handling' },
    { id: 'data-models', title: 'Data Models' },
    { id: 'examples', title: 'Code Examples' },
    { id: 'changelog', title: 'Changelog' },
  ];

  const renderContent = () => {
    switch (activeSection) {
      case 'introduction':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Introduction to Qonta API</h2>
            <p className="mb-4">Welcome to our comprehensive Qonta API documentation. This API provides access to rich datasets including company information, people profiles, and job listings.</p>
            <p className="mb-4">Our API is designed to help businesses make informed decisions, conduct market research, and enhance their data-driven strategies. With our API, you can:</p>
            <ul className="list-disc list-inside mb-4">
              <li>Retrieve detailed company profiles</li>
              <li>Access comprehensive information about individuals</li>
              <li>Gather insights on job market trends</li>
            </ul>
            <p>To get started, explore our documentation to learn about authentication, available endpoints, and how to make your first API request.</p>
          </div>
        );
      case 'authentication':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Authentication</h2>
            <p className="mb-4">Our API uses API keys for authentication. To obtain an API key, please contact our sales team.</p>
            <p className="mb-4">Include your API key in the Authorization header of each request:</p>
            <SyntaxHighlighter language="javascript" style={tomorrowNight}>
              {`Authorization: Bearer <your_api_key>`}
            </SyntaxHighlighter>
          </div>
        );
      case 'endpoints':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">API Endpoints</h2>
            <p className="mb-4">Our API provides the following main endpoints:</p>
            <ul className="list-disc list-inside mb-4">
              <li>
                <strong>/api/company/profile</strong>: Retrieve company information
              </li>
              <li>
                <strong>/api/person/profile</strong>: Access individual profiles
              </li>
              <li>
                <strong>/api/job/details</strong>: Get job listing information
              </li>
            </ul>
            <p>For detailed information on request and response formats, please refer to the respective dataset documentation.</p>
          </div>
        );
      case 'rate-limiting':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Rate Limiting</h2>
            <p className="mb-4">To ensure fair usage and maintain service quality, we implement rate limiting on our API:</p>
            <ul className="list-disc list-inside mb-4">
              <li>1000 requests per minute per API key</li>
              <li>10,000 requests per day per API key</li>
            </ul>
            <p>If you exceed these limits, you'll receive a 429 Too Many Requests response. Please contact us if you need higher limits.</p>
          </div>
        );
      case 'error-handling':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Error Handling</h2>
            <p className="mb-4">Our API uses conventional HTTP response codes to indicate success or failure of requests:</p>
            <ul className="list-disc list-inside mb-4">
              <li>2xx: Success</li>
              <li>4xx: Client errors</li>
              <li>5xx: Server errors</li>
            </ul>
            <p className="mb-4">Error responses will include a JSON object with more details:</p>
            <SyntaxHighlighter language="json" style={tomorrowNight}>
              {`{
  "error": {
    "code": "error_code",
    "message": "A description of the error"
  }
}`}
            </SyntaxHighlighter>
          </div>
        );
      case 'data-models':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Data Models</h2>
            <p className="mb-4">Our API returns data in JSON format. Here are the main data models:</p>
            <h3 className="text-lg font-bold mb-2">Company Profile</h3>
            <SyntaxHighlighter language="json" style={tomorrowNight}>
              {`{
  "company_name": "string",
  "domain": "string",
  "description": "string",
  "industry": "string",
  "logo_url": "string",
  "founded_year": "integer",
  "employee_count": "integer",
  "revenue": "string",
  "locations": [
    {
      "address": "string",
      "city": "string",
      "state": "string"
    }
  ],
  "headquarters": {
    "address": "string",
    "city": "string",
    "state": "string"
  },
  "social_links": {
    "twitter": "string",
    "facebook": "string",
    "instagram": "string",
    "linkedin": "string"
  }
}`}
            </SyntaxHighlighter>
            <p className="mt-4">For other data models, please refer to the respective dataset documentation.</p>
          </div>
        );
      case 'examples':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Code Examples</h2>
            <p className="mb-4">Here's an example of how to make a request to our API using JavaScript:</p>
            <SyntaxHighlighter language="javascript" style={tomorrowNight}>
              {`fetch('https://serve.qonta.co/api/company/profile', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer <your_api_key>',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    companyName: 'Example Inc'
  })
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));`}
            </SyntaxHighlighter>
          </div>
        );
      case 'changelog':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Changelog</h2>
            <ul className="list-disc list-inside">
              <li>v1.0.0 (2023-06-01): Initial API release</li>
              <li>v1.1.0 (2023-07-15): Added job listings endpoint</li>
              <li>v1.2.0 (2023-08-30): Enhanced company profiles with social media links</li>
            </ul>
          </div>
        );
      default:
        return <div>Select a section from the sidebar to view documentation.</div>;
    }
  };

  return (
    <div className="flex space-x-6" style={{ fontSize: '0.924rem', lineHeight: '1.275' }}>
      <div className="w-1/4 bg-white p-6 rounded-xl shadow-md border-2 border-gray-100">
        <h2 className="text-lg font-bold mb-4 text-gray-800">Documentation</h2>
        <nav>
          {sections.map((section) => (
            <Button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`w-full justify-start mb-2 ${
                activeSection === section.id ? 'bg-black text-white' : 'bg-gray-100 text-gray-800 hover:bg-black hover:text-white'
              }`}
            >
              {section.title}
            </Button>
          ))}
        </nav>
      </div>
      <div className="w-3/4 bg-white p-6 rounded-xl shadow-md border-2 border-gray-100">
        {renderContent()}
      </div>
    </div>
  );
};

export default Documentation;
