import { Activity } from "lucide-react";

const TopEndpoints = ({ topEndpoints }: { topEndpoints: string[] }) => {
  return (
    <div className="p-4 rounded-xl shadow-md bg-white border-2 border-gray-100">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-semibold">Top Endpoints</h3>
        <div className="p-1.5 bg-black bg-opacity-5 rounded-full">
          <Activity size={18} />
        </div>
      </div>
      <ol className="list-decimal list-inside space-y-1">
        {topEndpoints.map((endpoint, index) => (
          <li key={index} className="text-sm text-gray-600">
            {endpoint}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TopEndpoints;
