import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Home from "./pages/home/Home";
import Billing from "./pages/billing/Billing";
import Settings from "./pages/settings/Settings";
import Api from "./pages/api/Api";
import Datasets from "./pages/datasets/Datasets";
import Dataset from "./pages/datasets/id/Dataset";
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup/Signup";
import Documentation from "./pages/documentation/Documentation";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/api",
          element: <Api />,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/billing",
          element: <Billing />,
        },
        {
          path: "/datasets",
          element: <Datasets />,
        },
        {
          path: "/datasets/:id",
          element: <Dataset />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/documentation",
          element: <Documentation />,
        }
      ],
    },
    {
      path: "/sign-in",
      element: <Signin />,
    },
    {
      path: "/sign-up",
      element: <Signup />,
    },
  ]);

  return <RouterProvider router={routes} />;
}

export default App;
