import { useEffect, useState } from "react";
import DatasetContent from "./DatasetContent";

const Dataset = () => {
  const [dataset, setDataset] = useState<string>("");

  const companies = {
    overview: "The Companies Dataset provides comprehensive information about various businesses. It includes details such as company names, industries, locations, employee counts, revenue, and social media presence. This dataset is ideal for market research, lead generation, and business intelligence applications.",
    description:
      "A dataset containing information about companies. This dataset contains information about companies such as their name, industry, and location.",
    link: "/datasets/companies",
    requestFormat: `POST /api/company/profile HTTP/1.1
Host: serve.qonta.co
Authorization: Bearer <api_key>
Content-Type: application/json

{
"companyName": "Raynor Inc"
}
`,

    sampleRequest: `fetch('https://serve.qonta.co/api/company/profile', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer <api_key>',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    companyName: 'Raynor Inc'
  })
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));

`,

    sampleResponse: `{
    "success": true,
    "error": null,
    "data": {
        "company_name": "Raynor Inc",
        "domain": "slushy-laptop.org",
        "description": "Polarised global encryption",
        "industry": "Real Estate",
        "logo_url": "https://loremflickr.com/640/480?lock=125687243472896",
        "founded_year": 1718362599078,
        "employee_count": 8798,
        "revenue": "893.12",
        "locations": [
            {
                "address": "528 Kunze Branch",
                "city": "Geraldbury",
                "state": "KS"
            },
            {
                "address": "5424 Bogisich Squares",
                "city": "Lake Savannastead",
                "state": "AL"
            }
        ],
        "headquarters": {
            "address": "528 Kunze Branch",
            "city": "Geraldbury",
            "state": "KS"
        },
        "social_links": {
            "twitter": "Connor52",
            "facebook": "Kamille_Breitenberg",
            "instagram": "Lowell.Wintheiser99",
            "linkedin": "Carmen_Wintheiser50"
        }
    },
    "cached": true
}
`,
responseSchema: `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "success": {
      "type": "boolean"
    },
    "error": {
      "type": ["string", "null"]
    },
    "data": {
      "type": "object",
      "properties": {
        "company_name": {
          "type": "string"
        },
        "domain": {
          "type": "string"
        },
        "description": {
          "type": "string"
        },
        "industry": {
          "type": "string"
        },
        "logo_url": {
          "type": "string",
          "format": "uri"
        },
        "founded_year": {
          "type": "integer"
        },
        "employee_count": {
          "type": "integer"
        },
        "revenue": {
          "type": "string"
        },
        "locations": {
          "type": "array",
          "items": {
            "type": "object",
            "properties": {
              "address": {
                "type": "string"
              },
              "city": {
                "type": "string"
              },
              "state": {
                "type": "string"
              }
            },
            "required": ["address", "city", "state"]
          }
        },
        "headquarters": {
          "type": "object",
          "properties": {
            "address": {
              "type": "string"
            },
            "city": {
              "type": "string"
            },
            "state": {
              "type": "string"
            }
          },
          "required": ["address", "city", "state"]
        },
        "social_links": {
          "type": "object",
          "properties": {
            "twitter": {
              "type": "string"
            },
            "facebook": {
              "type": "string"
            },
            "instagram": {
              "type": "string"
            },
            "linkedin": {
              "type": "string"
            }
          },
          "required": ["twitter", "facebook", "instagram", "linkedin"]
        }
      },
      "required": [
        "company_name",
        "domain",
        "description",
        "industry",
        "logo_url",
        "founded_year",
        "employee_count",
        "revenue",
        "locations",
        "headquarters",
        "social_links"
      ]
    },
    "cached": {
      "type": "boolean"
    }
  },
  "required": ["success", "error", "data", "cached"]
}
`
  };

  const people = {
    overview: "The People Dataset offers detailed information about individuals, including their personal and professional details. It contains data such as names, ages, locations, occupations, education levels, and social media profiles. This dataset is valuable for user profiling, demographic analysis, and personalized marketing strategies.",
    description: "A dataset containing information about people such as their name, age, and location. This dataset is useful for creating user profiles.",
    link: "/datasets/people",
    requestFormat: `POST /api/person/profile HTTP/1.1
Host: serve.qonta.co
Authorization: Bearer <api_key>
Content-Type: application/json

{
"personName": "John Doe"
}`,
    sampleRequest: `fetch('https://serve.qonta.co/api/person/profile', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer <api_key>',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    personName: 'John Doe'
  })
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));`,
    sampleResponse: `{
  "success": true,
  "error": null,
  "data": {
    "name": "John Doe",
    "age": 35,
    "location": "New York, NY",
    "occupation": "Software Engineer",
    "education": "Master's in Computer Science",
    "social_media": {
      "twitter": "@johndoe",
      "linkedin": "john-doe-123"
    }
  },
  "cached": true
}`,
    responseSchema: `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "success": { "type": "boolean" },
    "error": { "type": ["string", "null"] },
    "data": {
      "type": "object",
      "properties": {
        "name": { "type": "string" },
        "age": { "type": "integer" },
        "location": { "type": "string" },
        "occupation": { "type": "string" },
        "education": { "type": "string" },
        "social_media": {
          "type": "object",
          "properties": {
            "twitter": { "type": "string" },
            "linkedin": { "type": "string" }
          }
        }
      },
      "required": ["name", "age", "location", "occupation", "education", "social_media"]
    },
    "cached": { "type": "boolean" }
  },
  "required": ["success", "error", "data", "cached"]
}`
  };

  const jobs = {
    overview: "The Jobs Dataset provides a comprehensive collection of job-related information. It includes details such as job titles, salaries, locations, required skills, and company information. This dataset is useful for job market analysis, career planning, and recruitment strategies.",
    description: "A dataset containing information about jobs such as the job title, salary, and location.",
    link: "/datasets/jobs",
    requestFormat: `POST /api/job/details HTTP/1.1
Host: serve.qonta.co
Authorization: Bearer <api_key>
Content-Type: application/json

{
"jobTitle": "Software Engineer"
}`,
    sampleRequest: `fetch('https://serve.qonta.co/api/job/details', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer <api_key>',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    jobTitle: 'Software Engineer'
  })
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));`,
    sampleResponse: `{
  "success": true,
  "error": null,
  "data": {
    "job_title": "Software Engineer",
    "salary_range": "$80,000 - $150,000",
    "location": "San Francisco, CA",
    "required_skills": ["JavaScript", "React", "Node.js"],
    "company": "Tech Innovations Inc.",
    "job_description": "We are seeking a talented Software Engineer to join our team...",
    "experience_level": "3-5 years"
  },
  "cached": true
}`,
    responseSchema: `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "success": { "type": "boolean" },
    "error": { "type": ["string", "null"] },
    "data": {
      "type": "object",
      "properties": {
        "job_title": { "type": "string" },
        "salary_range": { "type": "string" },
        "location": { "type": "string" },
        "required_skills": { "type": "array", "items": { "type": "string" } },
        "company": { "type": "string" },
        "job_description": { "type": "string" },
        "experience_level": { "type": "string" }
      },
      "required": ["job_title", "salary_range", "location", "required_skills", "company", "job_description", "experience_level"]
    },
    "cached": { "type": "boolean" }
  },
  "required": ["success", "error", "data", "cached"]
}`
  };

  useEffect(() => setDataset(window.location.pathname.split("/")[2]), []);

  return (
    <div className="h-[80vh] pr-5 overflow-auto">
      <h2>Dataset</h2>
      {dataset === "companies" && <DatasetContent data={companies} />}
      {dataset === "people" && <DatasetContent data={people} />}
      {dataset === "jobs" && <DatasetContent data={jobs} />}
    </div>
  );
};

export default Dataset;
