import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { createApi } from "@/middlewares/createApi";
import { useAuth } from "@clerk/clerk-react";
import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface Key {
  name: string;
  key: string;
}

interface Verification {
  time: number;
  success: number;
  rateLimited: number;
  usageExceeded: number;
}

const ApiLogs = ({ keys }: { keys: Key[] }) => {
  const [verifications, setVerifications] = useState<Verification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [key, setKey] = useState<Key | null>(null);

  const { getToken } = useAuth();
  const api = createApi(getToken);

  useEffect(() => {
    if (!key && keys.length > 0) setKey(keys[0]);

    if (key) {
      api
        .post("/keys/logs", { key: key.key, page: currentPage, limit: 10 })
        .then((res) => {
          setVerifications(res.data.logs);
          setTotalPages(res.data.totalPages);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [key, currentPage]);

  const changeKey = (key: string) => {
    console.error(key);
    const newKey = keys.find((k) => k.name === key);
    if (newKey) setKey(newKey);
  };

  const parseDate = (time: number) => {
    const date = new Date(time);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="mt-5" style={{ fontSize: '1rem' }}>
      <div className="mb-4">
        {keys.length > 0 ? (
          <Select onValueChange={changeKey}>
            <SelectTrigger className="w-[200px] bg-white">
              <SelectValue placeholder="Key" />
            </SelectTrigger>
            <SelectContent>
              {keys?.map((key) => (
                <SelectItem key={key?.name} value={key?.name}>
                  {key?.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <p>No keys found</p>
        )}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Total Usage</TableHead>
            <TableHead>Success</TableHead>
            <TableHead>Rate Limited</TableHead>
            <TableHead>Usage Exceeded</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {verifications.map((verification) => (
            <TableRow key={verification.time}>
              <TableCell>{parseDate(verification.time)}</TableCell>
              <TableCell>{verification.success + verification.rateLimited + verification.usageExceeded}</TableCell>
              <TableCell>{verification.success}</TableCell>
              <TableCell>{verification.rateLimited}</TableCell>
              <TableCell>{verification.usageExceeded}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <ChevronLeft size={16} />
          Previous
        </Button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <Button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
          <ChevronRight size={16} />
        </Button>
      </div>
    </div>
  );
};

export default ApiLogs;
