import { SignOutButton } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import {
  Cloud,
  FileText,
  House,
  LogOut,
  Server,
  Settings,
  Wallet,
} from "lucide-react";
import { useState } from "react";

const Sidebar = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const topItems = [
    {
      name: "Home",
      path: "/",
      icon: <House size={20} />,
    },
    {
      name: "API",
      path: "/api",
      icon: <Cloud size={20} />,
    },
    {
      name: "Datasets",
      path: "/datasets",
      icon: <Server size={20} />
    },
    {
      name: "Documentation",
      path: "/documentation",
      icon: <FileText size={20} />,
    }
  ];

  const bottomItems = [
    {
      name: "Billing",
      path: "/billing",
      icon: <Wallet size={20} />,
    },
    {
      name: "Settings",
      path: "/settings",
      icon: <Settings size={20} />,
    },
  ];

  return (
    <div className="w-[15%] py-5 flex flex-col items-center justify-between px-2 mr-5 border-2 rounded-xl bg-gray-900 text-white">
      <div className="w-full flex items-center flex-col">
        <div>
          <h1 className="text-3xl font-extrabold text-center">Qonta</h1>
        </div>
        <div className="w-full mt-5">
          {topItems.map((item, index) => (
            <div
              className={`flex mt-2 items-center gap-2 px-2 py-2 text-[15.5px] w-full hover:bg-gray-800 duration-200 transition-colors cursor-pointer rounded-lg ${
                active === index ? "font-bold text-white" : "text-gray-300"
              }`}
              key={item.name}
              onClick={() => {
                setActive(index);
                navigate(item.path);
              }}
            >
              <div className="p-2 rounded-xl">{item.icon}</div>
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex items-center flex-col">
        {bottomItems.map((item, index) => (
          <div
            className={`flex items-center gap-2 px-2 py-2 text-[15.5px] w-full hover:bg-gray-800 duration-200 transition-colors cursor-pointer rounded-lg
            ${active === (index + topItems.length) ? "font-bold text-white" : "text-gray-300"}`}
            onClick={() => {
              setActive(index + topItems.length);
              navigate(item.path);
            }}
            key={item.name}
          >
            <div className="p-2 rounded-xl">{item.icon}</div>
            <div>{item.name}</div>
          </div>
        ))}

        <SignOutButton>
          <div
            className={`flex items-center gap-3 px-2 py-2 text-[15.5px] w-full hover:bg-gray-800 duration-200 transition-colors cursor-pointer rounded-lg
            ${active === (bottomItems.length + topItems.length) ? "font-bold text-white" : "text-gray-300"}`}
            onClick={() => {
              setActive(bottomItems.length + topItems.length);
            }}
          >
            <div className="p-2 rounded-xl">
              <LogOut size={20} />
            </div>
            <div>Logout</div>
          </div>
        </SignOutButton>
      </div>
    </div>
  );
};

export default Sidebar;
