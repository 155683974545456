import Plans from "./Plans";
import { Button } from "@/components/ui/button";
import { createApi } from "@/middlewares/createApi";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import Credits from "./Credits";
import { Tabs, TabsTrigger, TabsContent, TabsList } from "@/components/ui/tabs";

const Billing = () => {
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentCredits, setCurrentCredits] = useState(0);

  const launchPortal = () => {
    const api = createApi(getToken);

    api
      .post("/plan/portal-session")
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred");
      });
  };

  const { getToken } = useAuth();

  const processPlan = (plan: string) => {
    const api = createApi(getToken);

    api
      .post("/plan/subscribe", { plan })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred");
      });
  };

  const addCredits = (credits: number) => {
    const api = createApi(getToken);

    api
      .post("/plan/credits", { credits })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.error(error);
        alert("An error occurred");
      });
  };

  useEffect(() => {
    const api = createApi(getToken);

    api
      .get("/plan/current")
      .then((res) => {
        const plan = res.data.plan;
        setCurrentPlan(plan);
        setCurrentCredits(res.data.credits);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="space-y-4" style={{ fontSize: '1.32rem', lineHeight: '1.275' }}>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-800">Billing</h2>
        <Button variant="outline" onClick={launchPortal} className="bg-black text-white hover:bg-black/90">
          Customer Portal
        </Button>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-md border-2 border-gray-100">
        <Tabs defaultValue="plans">
          <TabsList className="bg-gray-100 p-1 rounded-lg">
            <TabsTrigger value="plans" className="px-4 py-2 rounded-md">Plans</TabsTrigger>
            <TabsTrigger value="credits" className="px-4 py-2 rounded-md">Credits</TabsTrigger>
          </TabsList>
          <TabsContent value="plans">
            <Plans processPlan={processPlan} currentPlan={currentPlan} />
          </TabsContent>
          <TabsContent value="credits">
            <Credits
              currentPlan={currentPlan}
              addCredits={addCredits}
              currentCredits={currentCredits}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Billing;
