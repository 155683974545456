import { UserProfile } from "@clerk/clerk-react";

const Settings = () => {
  return (
    <div className="max-h-full overflow-auto bg-white rounded-lg shadow-sm">
      <UserProfile
        appearance={{
          elements: {
            rootBox: {
              width: "100%",
            },
            cardBox: {
              width: "100%",
              border: "none",
              boxShadow: "none",
            },
          },
        }}
      />
    </div>
  );
};

export default Settings;
