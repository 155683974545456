import { createApi } from "@/middlewares/createApi";
import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";
import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  useAuth,
} from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "sonner";

const Layout = () => {
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState("");

  const { getToken } = useAuth();
  useEffect(() => {
    const api = createApi(getToken);

    api
      .get("/user/getcredits")
      .then((res) => {
        setCredits(res.data.remaining || 0);
        setPlan(res.data.plan || "");
      })
      .finally(() => setLoading(false));
  });

  return (
    <>
      <SignedIn>
        <Toaster richColors />
        <div className="flex h-[100vh] relative p-5">
          <Sidebar />
          <div className="w-full h-full">
            <Topbar credits={credits} loading={loading} plan={plan} />
            <div className="w-full h-[86vh] mt-6 p-7 justify-center border-2 rounded-lg overflow-y-auto">
              <Outlet context={{ credits }} />
            </div>
          </div>
        </div>
      </SignedIn>

      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

export default Layout;
