import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Copy, EllipsisVertical } from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { createApi } from "@/middlewares/createApi";
import { useAuth } from "@clerk/clerk-react";
import { toast } from "sonner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface Key {
  name: string;
  key: string;
}

const ApiKey = ({
  keys,
  setKeys,
}: {
  keys: Key[];
  setKeys: (keys: Key[]) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState("");

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteKey, setDeleteKey] = useState<Key | null>(null);

  const { getToken } = useAuth();
  const api = createApi(getToken);

  const generateKeyFn = () => {
    api
      .post("/keys/generate", { name: newKeyName })
      .then((res) => {
        setKeys([...keys, res.data.key]);
        setIsOpen(false);
        setNewKeyName("");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.error || "Failed to generate API Key");
      });
  };

  const deleteKeyFn = () => {
    api
      .post("/keys/delete", { keyId: deleteKey?.key })
      .then(() => {
        setKeys(keys.filter((key) => key.key !== deleteKey?.key));
        setIsDeleteOpen(false);
        setDeleteKey(null);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to delete API Key");
      });
  };

  const copyKey = (key: string) => {
    navigator.clipboard.writeText(key);
    toast.success("Key Copied to Clipboard");
  };

  return (
    <div>
      <div className="p-6 border shadow-md rounded-xl mt-5">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Bearer Token (API Key)</h3>
          <Button onClick={() => setIsOpen(true)}>
            {keys?.length === 0 ? "Generate API Key" : "Generate More API Keys"}
          </Button>
        </div>

        {!keys?.length && (
          <div className="flex items-center justify-center flex-col gap-5 py-3">
            <p className="text-gray-500" style={{ fontSize: "1rem" }}>
              No API Keys Found
            </p>
          </div>
        )}

        {keys?.length > 0 && (
          <Table className="mt-3">
            <TableHeader>
              <TableRow className="border-none">
                <TableHead>TOKEN</TableHead>
                <TableHead>
                  <Badge variant="secondary" className="bg-green-200">
                    LABEL
                  </Badge>
                </TableHead>
                <TableHead>ACTIONS</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {keys?.map((key) => (
                <TableRow key={key?.key} className="border-none">
                  <TableCell>
                    <div className="flex gap-5">
                      <p>{key?.key}</p>
                      <Copy size={16} onClick={() => copyKey(key?.key)} className="cursor-pointer" />
                    </div>
                  </TableCell>
                  <TableCell>
                    <p>{key?.name}</p>
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <EllipsisVertical size={16} />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          onClick={() => {
                            setDeleteKey(key);
                            setIsDeleteOpen(true);
                          }}
                        >
                          Delete
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Generate a Key</DialogTitle>
            <DialogDescription>
              <p>Give Your Key a Meaningful Name</p>
              <Input
                className="mt-3"
                placeholder="Key Name"
                value={newKeyName}
                onChange={(e) => setNewKeyName(e.target.value)}
              />
              <Button
                className="w-full mt-3 bg-black text-white hover:bg-black/90"
                onClick={generateKeyFn}
              >
                Generate Key
              </Button>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are You Sure?</DialogTitle>
            <DialogDescription>
              <p>Are you sure you want to delete this key?</p>
              <div className="flex gap-5 w-full items-center mt-5">
                <Button
                  className="w-full"
                  onClick={() => {
                    setIsDeleteOpen(false);
                    setDeleteKey(null);
                  }}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  className="w-full"
                  onClick={deleteKeyFn}
                  variant="destructive"
                >
                  Delete
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ApiKey;
