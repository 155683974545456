import { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { useAuth } from "@clerk/clerk-react";
import { createApi } from "@/middlewares/createApi";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import ApiKey from "./ApiKey";
import ApiLogs from "./ApiLogs";
import RecentApiLogs from "./RecentApiLogs";
import TopEndpoints from "./TopEndpoints";

interface Key {
  name: string;
  key: string;
}

interface LogEntry {
  time: string;
  endpoint: string;
  status: string;
  responseTime: number;
  _id: string;
}

interface DataPoint {
  _id: string;
  date: string;
  value: number;
  name: string;
}

const Api = () => {
  const [keys, setKeys] = useState<Key[]>([]);
  const [credits, setCredits] = useState<number>(0);
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [topEndpoints, setTopEndpoints] = useState<string[]>([]);

  const { getToken } = useAuth();
  const api = createApi(getToken);
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/dashboard/get")
      .then((res) => {
        const data = res.data;
        setCredits(data.usage);
        setTotal(data.allowed);
        setLogs(data.logs);
        setTopEndpoints(
          data.topEndpoints.map((endpoint: DataPoint) => endpoint.name)
        );

        setProgress((data.usage / data.allowed) * 100);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to fetch API data");
      });
  }, []);

  useEffect(() => {
    api
      .post("/keys/get")
      .then((res) => {
        setKeys(res.data.keys);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to fetch API Keys");
      });
  }, []);

  return (
    <div
      className="space-y-4"
      style={{ fontSize: "1.32rem", lineHeight: "1.275" }}
    >
      <h2 className="text-2xl font-bold text-gray-800">API Management</h2>
      <div className="bg-white p-6 rounded-xl shadow-md border-2 border-gray-100">
        <Tabs defaultValue="key" className="w-full">
          <TabsList className="bg-gray-100 p-1 rounded-lg">
            <TabsTrigger value="key" className="px-4 py-2 rounded-md">
              API Keys
            </TabsTrigger>
            <TabsTrigger value="logs" className="px-4 py-2 rounded-md">
              API Logs
            </TabsTrigger>
          </TabsList>
          <TabsContent value="key">
            <div className="space-y-8">
              <ApiKey keys={keys} setKeys={setKeys} />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-6 border shadow-md rounded-xl">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold">Credits</h3>
                    <Button onClick={() => navigate("/billing")}>
                      Buy More Credits
                    </Button>
                  </div>
                  <Progress value={progress} className="w-full h-2 mb-2" />
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">
                      {credits} Credits Used
                    </span>
                    <span className="text-gray-600">{total} Total Credits</span>
                  </div>
                </div>
                <TopEndpoints topEndpoints={topEndpoints} />
              </div>
              <RecentApiLogs logs={logs} />
            </div>
          </TabsContent>
          <TabsContent value="logs">
            <ApiLogs keys={keys} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Api;
