import axios from "axios";
const serverEndpoint = import.meta.env.VITE_SERVER_ENDPOINT;
const logsEndpoint = import.meta.env.VITE_LOGS_ENDPOINT;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createApi(getToken: any) {
  const api = axios.create({
    baseURL: serverEndpoint,
  });

  api.interceptors.request.use(async (request) => {
    const token = await getToken();
    request.headers.Authorization = `Bearer ${token}`; // @ts-expect-error - metadata is not defined on AxiosRequestConfig
    request.metadata = { startTime: new Date() };
    return request;
  });

  api.interceptors.response.use(
    (response) => {
      const endTime = new Date(); // @ts-expect-error - metadata is not defined on AxiosResponse
      const executionTime = endTime - response.config.metadata.startTime;
      axios
        .post(`${logsEndpoint}/api/frli`, {
          logStatus: response.status,
          logMessage: `Request to ${response.config.url} took ${executionTime}ms`,
          level: "info",
        })
        .catch((error) => {
          console.error("Failed to log info:", error);
        });
      return response;
    },
    (error) => {
      const endTime = new Date();
      // @ts-expect-error - metadata is not defined on AxiosError
      const executionTime = endTime - error.config.metadata.startTime;
      axios
        .post(`${logsEndpoint}/api/frli`, {
          logStatus: error.response?.status || 500,
          logMessage: `Request to ${error.config.url} failed after ${executionTime}ms with error: ${error.message}`,
          level: "error",
        })
        .catch((error) => {
          console.error("Failed to log error:", error);
        });
      return Promise.reject(error);
    }
  );

  return api;
}
