import { UserButton } from "@clerk/clerk-react";
// import { Input } from "../ui/input";
// import { Search } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { CircleAlert } from "lucide-react";
import { useNavigate } from "react-router-dom";

const Topbar = ({
  credits,
  loading,
  plan,
}: {
  credits: number;
  loading: boolean;
  plan: string;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between backdrop-blur-sm sticky top-3 z-10">
      <div className="w-full flex justify-center">
        {credits === 0 && !loading && (
          <Alert
            variant="destructive"
            className="flex justify-between items-center bg-red-50 border-red-200 max-w-3xl"
          >
            <AlertDescription className="flex items-center justify-between w-full">
              <div className="flex items-center gap-3">
                <CircleAlert className="h-5 w-5 text-red-600" />
                <p className="text-red-700 font-medium">
                  You have no credits. Please buy some credits to continue using
                  the service.
                </p>
              </div>
              <a
                onClick={() => navigate("/billing")}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-300 cursor-pointer text-sm font-medium ml-4"
              >
                Buy Credits
              </a>
            </AlertDescription>
          </Alert>
        )}

        {plan === "expired" && !loading && (
          <Alert
            variant="destructive"
            className="flex justify-between items-center bg-red-50 border-red-200 max-w-3xl"
          >
            <AlertDescription className="flex items-center justify-between w-full">
              <div className="flex items-center gap-3">
                <CircleAlert className="h-5 w-5 text-red-600" />
                <p className="text-red-700 font-medium">
                  Your plan has expired. Please renew your plan to continue
                  using the service.
                </p>
              </div>
              <a
                onClick={() => navigate("/billing")}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-300 cursor-pointer text-sm font-medium ml-4"
              >
                Buy Credits
              </a>
            </AlertDescription>
          </Alert>
        )}
      </div>
      <UserButton />
    </div>
  );
};

export default Topbar;
