import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";

const Monthly = ({
  processPlan,
  currentPlan,
}: {
  processPlan: (plan: string) => void;
  currentPlan: string;
}) => {
  const plans = [
    {
      name: "Essential",
      price: "$39",
      credits: "10,000",
    },
    {
      name: "Premium",
      price: "$79",
      credits: "50,000",
    },
    {
      name: "Enterprise",
      price: "$499",
      credits: "1,000,000",
    },
  ];

  return (
    <div className="space-y-6">
      <p className="text-sm text-gray-600">
        Billed <b>Monthly</b>. Credits issued and expire every month.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className="rounded-lg p-6 flex flex-col gap-4 border-2 items-center shadow-sm hover:shadow-md transition-shadow duration-300"
          >
            <p className="text-xl font-semibold text-gray-700">
              {plan.name.toUpperCase()}
            </p>
            <div className="text-center">
              <p className="text-3xl font-bold">
                {plan.price} <sub className="text-sm font-normal">/month</sub>
              </p>
            </div>

            <p className="text-sm text-gray-600">{plan.credits} credits </p>

            {currentPlan === plan.name.toLowerCase() ? (
              <Badge variant="secondary" className="bg-green-100 text-green-800 py-1 px-3">
                Current Plan
              </Badge>
            ) : (
              <Button
                className="bg-black text-white hover:bg-black/90 w-full"
                onClick={() => processPlan(plan.name.toLowerCase())}
              >
                Select Plan
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Monthly;
