import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";

const Credits = ({
  currentPlan,
  addCredits,
  currentCredits,
}: {
  currentPlan: string;
  addCredits: (credits: number) => void;
  currentCredits: number;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    setIsActive(["enterprise", "premium", "essential"].includes(currentPlan));
  }, [currentPlan]);

  function calculateTotalPrice(credits: number) {
    const pricePerCredit = 0.0015;
    const total = credits * pricePerCredit;
    return (Math.ceil(total * 100) / 100 || 0).toFixed(2);
  }

  return (
    <div className="space-y-6">
      {isActive ? (
        <>
          <p className="text-sm text-gray-600">
            Additional credits will cost $0.0015 per credit.
          </p>
          <div className="rounded-lg p-6 flex flex-col gap-4 border-2 shadow-sm max-w-md">
            <p className="text-xl font-semibold text-gray-700">Buy Credits</p>

            <p className="text-sm text-gray-600">You currently have {currentCredits} credits remaining.</p>
            <Input
              type="number"
              placeholder="Enter the number of credits"
              value={credits}
              onChange={(e) => setCredits(parseInt(e.target.value))}
              className="mt-2"
            />
            <p className="text-sm text-gray-600">
              Credits will cost you a total of ${calculateTotalPrice(credits)} USD
            </p>
            <Button
              className="bg-black text-white hover:bg-black/90 w-full mt-4"
              onClick={() => addCredits(credits)}
            >
              Buy Credits
            </Button>
          </div>
        </>
      ) : (
        <p className="text-gray-600" style={{ fontSize: '1.12rem' }}>Please select a plan to buy credits</p>
      )}
    </div>
  );
};

export default Credits;
