import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs/";

interface Data {
  overview: string;
  requestFormat: string;
  sampleRequest: string;
  sampleResponse: string;
  responseSchema: string;
}

const DatasetContent = ({ data }: { data: Data }) => {
  return (
    <div>
      <Tabs defaultValue="overview" className="mt-5">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="sample">Sample</TabsTrigger>
          <TabsTrigger value="schema">Schema</TabsTrigger>
        </TabsList>
        <TabsContent value="overview">
          <h2 className="text-xl font-bold mb-3">Dataset Overview</h2>
          <p className="text-gray-700">{data.overview}</p>
        </TabsContent>
        <TabsContent value="sample">
          <h2 className="text-xl font-bold mb-3">Request Format</h2>
          <div className="p-3 bg-[#201c24] rounded-xl mt-2">
            <SyntaxHighlighter language="javascript" style={tomorrowNight}>
              {data.requestFormat}
            </SyntaxHighlighter>
          </div>

          <h2 className="text-xl font-bold mb-3 mt-5">Sample Request</h2>
          <div className="p-3 bg-[#201c24] rounded-xl mt-2">
            <SyntaxHighlighter language="javascript" style={tomorrowNight}>
              {data.sampleRequest}
            </SyntaxHighlighter>
          </div>

          <h2 className="text-xl font-bold mb-3 mt-5">Sample Response</h2>
          <div className="p-3 bg-[#201c24] rounded-xl mt-2">
            <SyntaxHighlighter language="json" style={tomorrowNight}>
              {data.sampleResponse}
            </SyntaxHighlighter>
          </div>
        </TabsContent>
        <TabsContent value="schema">
          <h2 className="text-xl font-bold mb-3">Response Schema</h2>
          <div className="p-3 bg-[#201c24] rounded-xl mt-2">
            <SyntaxHighlighter language="json" style={tomorrowNight}>
              {data.responseSchema}
            </SyntaxHighlighter>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default DatasetContent;
