import { SignIn as SignInComp } from "@clerk/clerk-react";

const Signin = () => {
  return (
    <div className="flex items-center justify-center h-[100vh]">
      <SignInComp />
    </div>
  );
};

export default Signin;
