import { SignUp as SignUpComp } from "@clerk/clerk-react";

const Signup = () => {
  return (
    <div className="flex items-center justify-center h-[100vh]">
      <SignUpComp />
    </div>
  );
};

export default Signup;
