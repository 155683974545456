import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
} from "recharts";
import { Progress } from "@/components/ui/progress";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  TrendingUp,
  Users,
  Briefcase,
  CreditCard,
  Settings,
  Activity,
} from "lucide-react";
import { useAuth } from "@clerk/clerk-react";
import { createApi } from "@/middlewares/createApi";
import { toast } from "sonner";

// Interfaces
interface TimeSeriesData {
  _id: string;
  date: string;
  value: number;
}

interface RecentData {
  _id: string;
  companies: TimeSeriesData[];
  jobs: TimeSeriesData[];
  people: TimeSeriesData[];
  total: string[];
  date: string;
}

interface TopEndpoint {
  name: string;
  count: number;
}

interface Log {
  time: string;
  endpoint: string;
  status: string;
  responseTime: number;
  _id: string;
}

interface DashboardData {
  usage: number;
  logs: Log[];
  recentData: RecentData[];
  topEndpoints: TopEndpoint[];
  allowed: number;
  plan: string;
}

interface CardData {
  title: string;
  value: string;
  description: string;
  data: TimeSeriesData[];
  icon: React.ReactNode;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const credits = (useOutletContext() as { credits: number }).credits;

  const [loading, setLoading] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    usage: 0,
    logs: [],
    recentData: [],
    topEndpoints: [],
    allowed: 0,
    plan: "",
  });

  const { getToken } = useAuth();

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const api = createApi(getToken);
        const response = await api.get("/dashboard/get");
        setDashboardData(response.data);
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch dashboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [getToken]);

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-200 rounded shadow-md">
          <p className="text-xs">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  const getLatestValue = (data: TimeSeriesData[]): number => {
    if (data && data.length > 0) {
      return data[data.length - 1].value;
    }
    return 0;
  };

  const recentData = dashboardData.recentData[0] || {
    companies: [],
    jobs: [],
    people: [],
  };

  const cards: CardData[] = [
    {
      title: "New Companies",
      value: `+${getLatestValue(recentData.companies)}`,
      description: "Companies Added",
      data: recentData.companies,
      icon: <Briefcase size={24} />,
    },
    {
      title: "New Contacts",
      value: `+${getLatestValue(recentData.people)}`,
      description: "Contacts Uploaded",
      data: recentData.people,
      icon: <Users size={24} />,
    },
    {
      title: "New Jobs",
      value: `+${getLatestValue(recentData.jobs)}`,
      description: "Jobs Added",
      data: recentData.jobs,
      icon: <TrendingUp size={24} />,
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="space-y-4"
      style={{ fontSize: "1.32rem", lineHeight: "1.275" }}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {cards.map((card, index) => (
          <div
            key={index}
            className="p-4 rounded-xl shadow-md bg-white border-2 border-gray-100"
            style={{ transform: "scale(0.9919)" }}
          >
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="flex items-center justify-between mb-2">
                  <h2 className="text-lg font-bold text-gray-800">
                    {card.title}
                  </h2>
                  <div className="p-1.5 bg-black bg-opacity-5 rounded-full">
                    {card.icon}
                  </div>
                </div>
                <p className="text-2xl font-extrabold text-gray-900 mb-1">
                  {card.value}
                </p>
                <span className="text-xs text-gray-500">
                  {card.description}
                </span>
              </div>
              <div className="h-20 mt-2">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={card.data}>
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      axisLine={false}
                      tick={{ fontSize: 10 }}
                      tickFormatter={(str) => {
                        const date = new Date(str);
                        return `${date.getMonth() + 1}/${date.getDate()}`;
                      }}
                    />
                    <YAxis hide />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#3b82f6"
                      strokeWidth={2}
                      dot={{ r: 3, fill: "#3b82f6" }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div
          className="p-4 rounded-xl shadow-md bg-white border-2 border-gray-100"
          style={{ transform: "scale(0.9919)" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-lg font-bold text-gray-800">API Credits</h2>
            <div className="p-1.5 bg-black bg-opacity-5 rounded-full">
              <CreditCard size={18} />
            </div>
          </div>
          <Progress
            value={(dashboardData.usage / dashboardData.allowed) * 100}
            className="w-full h-1.5 mb-1.5"
          />
          <p className="text-xs text-gray-500">
            {Math.max(0, dashboardData.allowed - credits)} / {dashboardData.allowed} credits
            used
          </p>
        </div>

        <div
          className="p-4 rounded-xl shadow-md bg-white border-2 border-gray-100"
          style={{ transform: "scale(0.9919)" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-lg font-bold text-gray-800">Current Plan</h2>
            <div className="p-1.5 bg-black bg-opacity-5 rounded-full">
              <Settings size={18} />
            </div>
          </div>
          <p className="text-2xl font-extrabold text-gray-900 mb-2">
            {dashboardData.plan.charAt(0).toUpperCase() +
              dashboardData.plan.slice(1)}
          </p>
          <Button
            onClick={() => navigate("/billing")}
            className="w-full bg-black text-white hover:bg-black/90 text-sm"
          >
            Buy Credits
          </Button>
        </div>

        <div
          className="p-4 rounded-xl shadow-md bg-white border-2 border-gray-100"
          style={{ transform: "scale(0.9919)" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-lg font-bold text-gray-800">Top Endpoints</h2>
            <div className="p-1.5 bg-black bg-opacity-5 rounded-full">
              <Activity size={18} />
            </div>
          </div>
          <ol className="list-decimal list-inside space-y-1">
            {dashboardData.topEndpoints.map((endpoint, index) => (
              <li key={index} className="text-xs text-gray-600">
                {endpoint.name}
              </li>
            ))}
          </ol>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-bold mb-2 text-gray-800">
          Recent API Requests
        </h2>
        <div className="border rounded-xl overflow-hidden shadow-md">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="bg-gray-50 text-gray-600 font-semibold text-[13px]">
                  Endpoint
                </TableHead>
                <TableHead className="bg-gray-50 text-gray-600 font-semibold text-[13px]">
                  Status
                </TableHead>
                <TableHead className="bg-gray-50 text-gray-600 font-semibold text-[13px]">
                  Response Time
                </TableHead>
                <TableHead className="bg-gray-50 text-gray-600 font-semibold text-[13px]">
                  Timestamp
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {dashboardData.logs.map((log, index) => (
                <TableRow key={index} className="text-[13px]">
                  <TableCell className="font-medium text-gray-700">
                    {log.endpoint}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`px-1.5 py-0.5 rounded-full text-[11px] font-medium ${
                        log.status === "200"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {log.status === "200" ? "Success" : "Failed"}
                    </span>
                  </TableCell>
                  <TableCell className="text-gray-600">
                    {log.responseTime}ms
                  </TableCell>
                  <TableCell className="text-gray-600">
                    {new Date(log.time).toLocaleString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Home;
